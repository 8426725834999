<template>
	<v-select
		:label="label"
		:items="months"
		:value="value"
		@change="output"
	></v-select>
</template>

<script>
	export default {
		name: "DatePicker",
		props: {
			value: String,
			label: String,
			earliest: {type: String, default: "01/01/2023"},
			latest: String
		},
		data: () => {
			return {
				modal: false,
				start: null,
				end: null
			};
		},
		computed: {
			months() {
				return this.dateRange(this.earliest, this.latest);
			}
		},
		methods: {
			output(v) {
				this.$emit("input", v)
			},
			dateRange(startDate) {
				let start = new Date(startDate);
				const end = new Date().setUTCHours(12);
				const dates = [];
				while (start <= end) {
					const displayMonth = start.getUTCMonth() + 1;
					dates.push({
						heading: start.getUTCFullYear(),
						text: [
							start.toLocaleString('default', { month: 'long' }),
							start.getUTCFullYear()
						].join(" "),
						value: [
							displayMonth.toString().padStart(2, "0"),
							"01",
							start.getUTCFullYear(),
						].join("/")
					});
					start = new Date(start.setUTCMonth(displayMonth));
				}

				return dates;
			}
		}
	};
</script>
