<template>
	<v-sheet>
		<render-content id="staffsurveyresponses"></render-content>

		<v-alert color="lightest">
			<p>
				Before accessing your staff survey data please select the earliest and
				latest dates below:
			</p>
			<v-row>
				<v-col>
					<submission-date-picker
						:value="start"
						@input="(v) => (start = v)"
						label="Earliest"
					></submission-date-picker
				></v-col>
				<v-col>
					<submission-date-picker
						:value="end"
						@input="(v) => (end = v)"
						:earliest="start"
						label="Latest"
					></submission-date-picker
				></v-col>
			</v-row>
			<p class="text-right">
				<v-btn color="success" @click="updateResponsesInRange">Update</v-btn>
			</p>
		</v-alert>
		<v-tabs v-model="tab">
			<v-toolbar>
				<v-tab>Overview by Job Function</v-tab>
				<v-tab>Overview by Service</v-tab>
				<v-tab>Chart</v-tab>
				<v-tab>All data</v-tab>
			</v-toolbar>
			<v-tabs-items v-model="tab">
				<v-tab-item>
					<count-results-by-job-function
						:responsesInDateRange="respondentsInDateRange"
					></count-results-by-job-function>
				</v-tab-item>
				<v-tab-item>
					<count-results-by-service
						:responsesInDateRange="respondentsInDateRange"
					></count-results-by-service>
				</v-tab-item>
				<v-tab-item>
					<staff-survey-viz
						:responsesInDateRange="responsesInDateRange"
						:id="staffSurveyId"
						v-if="section"
					></staff-survey-viz>
				</v-tab-item>
				<v-tab-item>
					<response-table
						:responsesInDateRange="responsesInDateRange"
						:id="staffSurveyId"
						v-if="section"
					></response-table>
					<export-data
						:id="staffSurveyId"
						:responsesInDateRange="responsesInDateRange"
						v-if="section"
					></export-data>
				</v-tab-item>
			</v-tabs-items>
		</v-tabs>
	</v-sheet>
</template>

<script>
	import CountResultsByJobFunction from "@c/staff-survey/CountResultsByJobFunction.vue";
	import CountResultsByService from "@c/staff-survey/CountResultsByService.vue";
	import SubmissionDatePicker from "@c/staff-survey/SubmissionDatePicker";
	import ResponseTable from "@c/staff-survey/ResponseTable.vue";
	import StaffSurveyViz from "@c/staff-survey/StaffSurveyViz.vue";
	import ExportData from "@c/staff-survey/ExportData.vue";
	import RenderContent from "@c/ui/RenderContent";
	export default {
		name: "StaffSurveyDashboard",
		data: () => {
			return {
				tab: 0,
				modal: false,
				start: "1/1/2023",
				end: null,
				responsesInDateRange: [],
				respondentsInDateRange: []
			};
		},
		computed: {
			organisation() {
				return this.$store.state.organisation.data.id;
			},
			staffSurveyId() {
				return this.$store.state.config.data["staff_survey"];
			},
			section() {
				return this.$store.state.sections.data[this.staffSurveyId];
			},
			respondents() {
				return this.$store.state.staffSurveyResponses.data;
			},
			responses() {
				return this.$store.getters["staffSurveyResponses/responses"];
			}
		},
		components: {
			SubmissionDatePicker,
			ResponseTable,
			CountResultsByJobFunction,
			ExportData,
			StaffSurveyViz,
			RenderContent,
			CountResultsByService
		},
		watch: {
			organisation: {
				immediate: true,
				handler(value) {
					if (value) {
						this.$store.dispatch("staffSurveyResponses/openDBChannel", {
							clauses: {
								where: [["organisation", "==", value]]
							}
						});
					}
				},
			},
			start(v) {
			if (!this.end) {
					return;
				}
				let s = new Date(v);
				let e = new Date(this.end);
				if (s > e) {
					this.end = null;
				}
			},
			end(v) {
				if( !v ){
					return;
				}
				let s = new Date(this.start);
				let e = new Date(v);
				if (s > e) {
					this.start = "1/1/2023";
				}
			},
			staffSurveyId: {
				immediate: true,
				handler(value) {
					if (value) {
						this.$store.dispatch("sections/fetchById", value);
					}
				}
			}
		},
		methods: {
			filterByDate: (data, start, end) => {
				start = start ? new Date(start) : new Date("01/01/2023");
				if (end) {
					end = new Date(end);
					end = new Date(end.setMonth(end.getMonth() + 1));
				} else {
					end = new Date();
				}
				return data.filter((d) => {
					let c = d.created_at.toDate();
					return c > start && c < end;
				});
			},
			updateResponsesInRange() {
				let responses = this.responses || [];
				this.responsesInDateRange = this.filterByDate(
					responses,
					this.start,
					this.end
				);

				let respondents = this.respondents
					? Object.values(this.respondents)
					: [];
				this.respondentsInDateRange = this.filterByDate(
					respondents,
					this.start,
					this.end
				);
			}
		},
		provide() {
			return {
				filterByDate: this.filterByDate
			};
		},
		created() {
			this.$store.dispatch("jobFunctions/openDBChannel");
			this.$store.dispatch("config/start", "default");
		}
	};
</script>
