<template>
	<v-sheet>
		<v-row>
			<v-col cols="8"
				><v-select
					v-model="question"
					:items="questions"
					label="Select Question"
				></v-select
			></v-col>
			<v-col cols="4">
				<v-item-group v-model="countBy">
					<v-item value="service" v-slot="{active, toggle}">
						<v-chip
							class="mr-2"
							@click="toggle"
							:color="active ? 'success' : 'secondary'"
							>Service Type</v-chip
						>
					</v-item>
					<v-item value="jobFunction" v-slot="{active, toggle}">
						<v-chip @click="toggle" :color="active ? 'success' : 'secondary'"
							>Job Role</v-chip
						>
					</v-item>
				</v-item-group>
			</v-col>
		</v-row>

		<bar-chart
			v-if="question"
			:height="400"
			:chart-options="chartOptions"
			:chart-data="chartData"
		></bar-chart>
	</v-sheet>
</template>

<script>
	import _ from "lodash";
	import BarChart from "@/assets/charts/BarChart.vue";

	export default {
		name: "StaffSurveyViz",
		props: {
			id: {type: String}, // the ID of the staff survey
			responsesInDateRange: { type: Array }
		},
		components: {
			BarChart
		},
		data: () => {
			return {
				countBy: null,
				question: false
			};
		},
		computed: {
			colors() {
				return this.$store.state.colors;
			},
			chartOptions() {
				let options = {};
				_.set(options, "scales.x.ticks.stepSize", 1);
				_.set(options, "scales.y.stacked", true);
				_.set(options, "scales.x.stacked", true);
				return options;
			},
			section() {
				return this.$store.state.sections.data[this.id];
			},
			questions() {
				const self = this;
				let questions = [];
				if (!self.section) {
					return questions;
				} else {
					questions = self.section.questions.map((q) => {
						let questionTexts = self.$store.getters[
							"questions/getQuestionTexts"
						](q, true);
						return {value: q, text: questionTexts.string};
					});
				}
				return questions;
			},
			jobFunctions() {
				return this.$store.getters["jobFunctions/options"];
			},
			services() {
				return this.$store.getters["services/options"];
			},
			questionResponses() {
				let q = this.question;
				if (!q) {
					return [];
				}
				return this.responsesInDateRange.filter((r) => r.question == q);
			},
			answerOptions() {
				if (!this.question) {
					return [];
				}
				let options =
					this.$store.getters["questions/getAnswerOptions"](this.question) ||
					[];
				options = Object.values(options);
				return _.sortBy(Object.values(options), "position");
			},
			labels() {
				return this.answerOptions.map((a) => a.text);
			},
			results() {
				const self = this;
				if (!self.countBy) {
					return [
						{
							label: "All Responses",
							data: self.questionResponses.map((response) => response.answer)
						}
					];
				} else {
					return self[`${self.countBy}s`].map((option) => {
						let responses = self.questionResponses.filter(
							(response) =>
								response[self.countBy] &&
								response[self.countBy].includes(option.id)
						);
						return {
							label: option.name,
							data: responses.map((response) => response.answer)
						};
					});
				}
			},
			countResults() {
				return this.results.map((element) => {
					return {label: element.label, data: _.countBy(element.data)};
				});
			},

			chartData() {
				const self = this;
				let datasets = self.countResults.map((results, i) => {
					return {
						skipNull: false,
						data: self.answerOptions.map((l) => results.data[l.value] || 0),
						label: results.label,
						backgroundColor: self.colors[i]
					};
				});
				return {
					labels: self.labels,
					datasets
				};
			}
		}
	};
</script>
