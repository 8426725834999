<template>
	<v-sheet>
		<h2 class="mb-4">Staff Survey</h2>
		<div v-html="$t('staff_survey.before_copy')"></div>
		<staff-survey-link></staff-survey-link>
		<div v-html="$t('staff_survey.after_copy')"></div>

	</v-sheet>
</template>

<script>
import StaffSurveyLink from "@c/staff-survey/Link.vue";
export default {
	name: "StaffSurveyDashboard",
	data: () => {
		return {

		};
	},
	computed: {
		organisation() {
			return this.$store.state.organisation.data.id;
		},
		staffSurveyId() {
			return this.$store.state.config.data["staff_survey"];
		},
		section() {
			return this.$store.state.sections.data[this.staffSurveyId];
		},
	},
	components: {
		StaffSurveyLink,
	},
	watch: {
		organisation: {
			immediate: true,
			handler(value) {
				if (value) {
					this.$store.dispatch("staffSurveyResponses/openDBChannel", {
						clauses: {
							where: [["organisation", "==", value]],
						},
					});
				}
			},
		},
		staffSurveyId: {
			immediate: true,
			handler(value) {
				if (value) {
					this.$store.dispatch("sections/fetchById", value);
				}
			},
		},
	},
	created() {
		this.$store.dispatch("jobFunctions/openDBChannel");
		this.$store.dispatch("config/start", "default");
	},
};
</script>
