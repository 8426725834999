<template>
	<p class="text-right">
		<export
			:columns="columns"
			:rows="rows"
			name="staff-survey-data"
			text="Export full dataset"
		></export>
	</p>
</template>

<script>
	import Export from "@c/downloads/Export";

	export default {
		name: "ExportData",
		props: {
			responsesInDateRange: [Array, Object]
		},
		computed: {
			columns() {
				return [
					{text: "ID", value: "id"},
					{text: "Submission Date", value: "created_at"},
					{text: "Question", value: "question"},
					{text: "Job Function", value: "jobFunction"},
					{text: "Service", value: "service"},
					{text: "Answer", value: "answer"},
					{text: "Score", value: "score"}
				];
			},
			categoryOptions() {
				return this.$store.state.categoryOptions.data;
			},
			rows() {
				const self = this;
				return this.responsesInDateRange.map((r) => {
					let row = {
						id: r.id,
						answer: r.processed_answer,
						score: r.score,
						created_at: r.created_at
					};

					row.jobFunction = r.jobFunction
						? r.jobFunction
								.map((id) => {
									return self.categoryOptions[id]
										? self.categoryOptions[id].name
										: "";
								})
								.join(",")
						: "";

					row.service = r.service
						? r.service
								.map((id) => {
									return self.categoryOptions[id]
										? self.categoryOptions[id].name
										: "";
								})
								.join(",")
						: "";

					let questionTexts = self.$store.getters["questions/getQuestionTexts"](
						r.question
					);
					row.question = questionTexts.string;
					row.created_at = row.created_at ? row.created_at.toDate() : "";
					return row;
				});
			}
		},
		components: {
			Export
		}
	};
</script>
