<template>
	<v-card flat>
        <v-card-text>
            <v-simple-table>
                <thead>
                    <tr>
                        <th>Service</th>
                        <th>Count</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="jf in services" :key="jf.id">
                        <td>{{ jf.name }}</td>
                        <td>{{ count(jf.id) }}</td>
                    </tr>
                </tbody>
            </v-simple-table>
        </v-card-text>
	</v-card>
</template>

<script>
export default {
	name: "CountResultsByService",
	props: {
		responsesInDateRange: { type: Array }
	},
	computed: {
		services() {
			return this.$store.getters["services/options"];
		},
	},
	methods: {
		count(j) {
			return Object.values(this.responsesInDateRange).filter(
				(a) => a["service"].includes(j)
			).length;
		},
	},
};
</script>
